<template>
  <div>
    <div v-if="displayTooltip">
      <v-tooltip color="#473068" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <span
                v-bind="attrs"
                v-on="on"
                >
              <div :class="className">{{ prependText }}{{ text | truncate(length, "...") }}{{ appendText }}</div>
              </span>
            </div>
          </template>
          <div class="hover-container">
              <span>{{ displayPrependOnHover ? prependText : "" }}{{ text }}{{ displayAppendOnHover ? appendText : "" }}</span>
          </div>
      </v-tooltip>
    </div>
    <div v-else>
      <div v-if="text" :class="className">{{ prependText }}{{ text }}{{ appendText }}</div>
    </div>
  </div>
</template>

<script>
export default {
 name: "TruncateHovering",
  props: {
    text: {
      type: String,
      default: "",
    },
    length: {
      type: Number,
      default: 10,
    },
    className: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "#473068",
    },
    truncateOnTextOverflow: {
      type: Boolean,
      default: false,
    },
    appendText: {
      type: String,
      default: "",
    },
    prependText: {
      type: String,
      default: "",
    },
    displayAppendOnHover: {
      type: Boolean,
      default: false,
    },
    displayPrependOnHover: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    truncate: function (text, stop, clamp) {
      if (stop > 3 && stop < text.length) {
        return text.slice(0, stop - 3) + clamp || "...";
      }
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },
  computed: {
    displayTooltip() {
      return this.text && (this.truncateOnTextOverflow || this.text.length > this.length);
    },
  }
};
</script>

<style scoped>
  .hover-container {
    max-width: 150px;
    word-wrap: anywhere;
  }
</style>
