<template>
  <div>
    <notifications
      group="notifications"
      position="top center"
      :max="3"
      width="80%"
      animation-name="v-fade-left"
    >
      <template slot="body" slot-scope="props">
        <div class="notification-template" :class="props.item.type">
          <div class="notification-template-content">
            <div class="notification-header">
              <div v-if="props.item.type == 'warn'" style="display: flex;">
                <div class="notification-template-icon">
                  <warning class="md-size-2x" style="margin: 20px 50px 20px 0px;"
                  /> 
                </div>
                <div> 
                  <div
                    style="margin: 15px 0px 10px 0px;"
                    class="notification-template-title"
                    v-html="props.item.title"
                  ></div>
                  <div
                    class="notification-template-text"
                    v-html="props.item.text"
                  ></div>
                </div>
              </div>
              <div class="notification-template-icon">
                <danger v-if="props.item.type == 'error'" class="md-size-2x"
                />
                <success v-if="props.item.type == 'success'" class="md-size-2x"
                />
              </div>
              <div
                v-if="props.item.type != 'warn'"
                class="notification-template-title"
                v-html="props.item.title"
              ></div>
            </div>
            <div
              v-if="props.item.type != 'warn'"
              class="notification-template-text"
              v-html="props.item.text"
            ></div>
          </div>
          <div class="notification-template-close" @click="props.close">
            <md-icon>close</md-icon>
          </div>
        </div>
      </template>
    </notifications>

    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
body {
  background-color:  $paginateText;
  overflow-x: visible;
  overflow-y: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.dashboard-core-view {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
  margin-bottom: 12px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.notification-template {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  &,
  & > div {
    box-sizing: border-box;
  }
  background: #e8f9f0;
  border: 2px solid #d0f2e1;

  .notification-template-icon {
    flex: 0 1 auto;
    color: #15c371;
    font-size: 32px;
    padding: 0 2px;
    margin-left: 10px;
  }

  .notification-template-close {
    flex: 0 1 auto;
    padding: 0 2px;
    font-size: 2px;
    opacity: 0.2;
    background: #ffffff;
    border-radius: 1000px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .notification-template-content {
    padding: 10px;
    flex: 1 0 auto;
    .notification-template-title {
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 600;
      margin-top: 4px;
      margin-left: 10px;
    }
  }

  .notification-header {
    display: flex;
  }

  .notification-template-text {
    margin-left: 48px;
  }

  .notification-template-close {
    top: 15px;
    right: 10px;
    opacity: 0.5;
    position: absolute;
  }

  &.warn {
    background: #EADAFF;
    color: #442D65;
    border: #fff3cd;
  }

  &.error {
    background: #FFCEDA;
    color: #442D65;
    border: #f5c6cb;
  }

  &.success {
    background: #A5E8E6;
    color: #442D65;
    border: #c6a8ec;
  }
}
.md-menu-content{
  margin-top: 50px !important;
  margin-left: 15px !important;
  width: 260px !important;
}
.v-menu__content {
  z-index: 10 !important;
}
.md-select-menu {
    z-index: 300 !important;
    width: 100%;
  }
.md-field.md-theme-default.md-has-value .md-input, .md-field.md-theme-default.md-has-value .md-textarea {
  -webkit-text-fill-color: $primaryDarkest !important;
}
</style>
