<template>
  <v-dialog
    v-model="active"
    :overlay-color="overlayColor"
    :overlay-opacity="overlayOpacity"
    :backdrop-filter="backdropFilter"
    :width="width"
    :max-width="maxWidth"
    @click:outside="close"
  >
    <div v-if="!fullScreen" class="header" :style="`
      height: ${height};
      max-height: ${maxHeight};
      min-height: ${minHeight};
    `">
      <div v-if="hasClose" class="close" :style="closeButtonStyle" @click="close"><popup-close style="float: right;" /></div>
      <div v-if="title" class="title-container" :style="`padding-left: ${hasBack ? '8%' : '0px'};`">
        <span v-if="hasBack" class="back" @click="back"><base-icon width="8" height="15" viewBox="0 0 8 15"><popup-back /></base-icon></span>
        <span class="title" :style="titleStyle">{{ title }}</span>
      </div>
      <div :class="noMargin ? '' : 'content'">
        <slot />
      </div>
    </div>
    <div v-else>
      <slot />
    </div>
  </v-dialog>
</template>

<script>
import PopupClose from "../../assets/icons/PopupClose.vue"
import PopupBack from "../../assets/icons/PopupBack.vue"
import BaseIcon from '../../views/dashboard/components/core/BaseIcon.vue'

export default {
  name: "PopupWindow",
  components: {
    BaseIcon,
    PopupClose,
    PopupBack
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    titleStyle: {
      type: String,
      default: "",
    },
    closeButtonStyle: {
      type: String,
      default: "",
    },
    hasClose: {
      type: Boolean,
      default: true
    },
    hasBack: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: ''
    },
    overlayColor: {
      type: String,
      default: '#0f051d'
    },
    overlayOpacity: {
      type: [Number,  String],
      default: 0.78
    },
    backdropFilter: {
      type: String,
      default: null
    },
    width: {
      type: [Number, String],
      default: '382px'
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    maxWidth: {
      type: [Number, String],
      default: '382px'
    },
    maxHeight: {
      type: [Number, String],
      default: 'auto'
    },
    minHeight: {
      type: [Number, String],
      default: 'auto'
    },
  },
  methods: {
    close() {
      this.$emit('popup-close');
    },
    back() {
      this.$emit('popup-back');
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    background-color: $popupWindow;
  }
  .close {
    padding: 18px;
    width: auto;
    cursor: pointer;
  }
  .title-container {
    padding-left: 8%;
    text-align: left;
  }
  .back {
    display: inline-block;
    align-items: center;
    cursor: pointer;
  }
  .title {
    font-size: 16px !important;
    margin-left: 8%;
    color: $primaryDark;
  }
  .content {
    margin: 0 16% 0 16%;
  }
</style>
