import axios from "axios";
import * as conf from "../conf.yml";
import restAdapter from "@/restAdapter";

const AUTH_KEY = "app-user-token";

declare const window: any;

/**
 * This module handle the auth user
 */
/* eslint-disable @typescript-eslint/camelcase */
export default {
  TOKEN_KEY: AUTH_KEY,
  authenticated: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    expiresAt: null,
    rememberToken: null,
    isRememberToken: false,
    roleId: "",
    userType: null,
    subRoles: [],
    brandName: '',
    phoneNumber: "",
    permissions: [],
    isRoleVerified: false,
    id: null,
    isCompany: false,
    avatar: null,
  },
  can: {}, // user permissions

  login(credentials: any) {
    const url = conf.default.server.host + "/api/login";
    const uninterceptedAxiosInstance = axios.create();

    return uninterceptedAxiosInstance
      .post(url, credentials)
      .then((response) => {
        if (response.data && response.data.success) {
          this.setAuth(response.data.success);
        }
        return response;
      });
  },
  logout() {
    const url = conf.default.server.host + "/api/logout";
    const options = {
      headers: { Authorization: this.getAuthToken() },
    };

    return axios.post(url, {}, options).then((response) => {
      this.updateAuth({
        firstName: "",
        lastName: "",
      });
      window.localStorage.removeItem(AUTH_KEY);
      window.Echo.leave("App.Models.User." + this.user.id);
      return response;
    }).catch(() => {
      this.updateAuth({
        firstName: "",
        lastName: "",
      });
      window.localStorage.removeItem(AUTH_KEY);
      window.Echo.leave("App.Models.User." + this.user.id);
    });
  },
  setAuth(data: any): void {
    this.user.firstName = data.first_name;
    this.user.lastName = data.last_name;
    this.user.email = data.email;
    this.user.token = data.token;
    this.user.rememberToken = data.rememberToken;
    this.user.expiresAt = data.expires_at;
    this.user.roleId = data.role_id;
    // TODO: backend change
    this.user.userType =
      typeof data.user_type == "object" ? data.user_type[0] : data.user_type;
    this.user.subRoles = data.subRoles;
    this.user.brandName = data.brand_name;
    this.user.phoneNumber = data.phone_number;
    this.user.permissions = [];
    this.user.isRoleVerified = data.role_verified_status;
    this.user.id = data.id;
    this.user.isCompany = data.is_company == 1;
    this.user.isRememberToken = data.isRememberToken;
    this.user.avatar = data.avatar;


    this.can = {}; // permissions

    window.localStorage.setItem(AUTH_KEY, JSON.stringify(this.user));

    window.Echo.connector.pusher.config.auth.headers["Authorization"] =
      "Bearer " + data.token;
  },
  updateAuth(data: any, updateLocalstorage = false): void {
    if (!data) {
      return;
    }

    this.user.firstName = data.firstName || this.user.firstName;
    this.user.lastName = data.lastName || this.user.lastName;
    this.user.email = data.email || this.user.email;
    this.user.token = data.token || this.user.token;
    this.user.rememberToken = data.rememberToken || this.user.rememberToken;
    this.user.expiresAt = data.expiresAt || this.user.expiresAt ;
    this.user.roleId = data.roleId || this.user.roleId;
    this.user.userType =  typeof data.userType == "object" ? data.userType[0] : data.userType || this.user.userType;
    this.user.subRoles = data.subRoles || this.user.subRoles;
    this.user.brandName = data.brandName || this.user.brandName;
    this.user.phoneNumber = data.phoneNumber || this.user.phoneNumber;
    this.user.permissions = data.permissions || this.user.permissions;
    this.user.isRoleVerified = data.isRoleVerified || this.user.isRoleVerified;
    this.user.id = data.id || this.user.id;
    this.user.isCompany = data.isCompany || this.user.isCompany;
    this.user.isRememberToken = data.isRememberToken || this.user.isRememberToken;
    this.user.avatar = data.avatar || this.user.avatar;

    if (updateLocalstorage === true) {
      window.localStorage.setItem(AUTH_KEY, JSON.stringify(this.user));
    }
  },
  updateAuthRoles(data: any): void {
    const authJson = window.localStorage.getItem(AUTH_KEY) as any;
    const authData = JSON.parse(authJson);

    authData.isCompany = data.isCompany;
    authData.subRoles = data.subRoles;

    this.updateAuth(authData);
    window.localStorage.setItem(AUTH_KEY, JSON.stringify(this.user));
  },
  checkAuth(): boolean {
    const authJson = window.localStorage.getItem(AUTH_KEY) as any;
    const authData = JSON.parse(authJson);
    this.updateAuth(authData);
    if (authData && authData.token) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  },
  checkUserTypeAssigned(): boolean {
    const authJson = window.localStorage.getItem(AUTH_KEY) as any;
    const authData = JSON.parse(authJson);
    this.updateAuth(authData);
    if (authData && authData.userType) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  },
  getAuthToken(): string {
    return "Bearer " + this.user.token;
  },
  getRememberToken(): string {
    return "Bearer " + this.user.rememberToken;
  },
};
