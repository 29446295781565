<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="stroke"
    :opacity="opacity"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.9997 10C19.9997 7.34775 18.9462 4.80411 17.0708 2.92896C15.1954 1.05355 12.6519 0 9.99985 0C7.34779 0 4.80404 1.05355 2.92891 2.92896C1.05353 4.80437 0 7.34791 0 10C0 12.6521 1.05353 15.1959 2.92891 17.071C4.80429 18.9465 7.34779 20 9.99985 20C12.651 19.997 15.1926 18.9423 17.0678 17.0677C18.9424 15.1929 19.9971 12.6511 20 9.9997L19.9997 10ZM9.99985 17.6474C7.97179 17.6474 6.02668 16.8416 4.59228 15.4076C3.15827 13.9733 2.35251 12.0282 2.35251 9.9999C2.35251 7.97161 3.15822 6.02667 4.59228 4.59225C6.02648 3.15822 7.97159 2.35244 9.99985 2.35244C12.0281 2.35244 13.973 3.15817 15.4074 4.59225C16.8414 6.02647 17.6472 7.97161 17.6472 9.9999C17.644 12.0272 16.8373 13.9704 15.4039 15.404C13.9704 16.8375 12.0273 17.6442 9.99985 17.6474Z" :fill="fill"/>
    <path d="M12.0278 6.46875L8.42766 10.7894L6.90708 8.96606C6.69764 8.71987 6.41427 8.58185 6.11891 8.58185C5.82355 8.58185 5.54018 8.71987 5.33073 8.96606C5.11904 9.21402 5 9.5518 5 9.90412C5 10.2563 5.11904 10.5941 5.33073 10.8422L7.63388 13.6036C7.84295 13.8558 8.12876 13.9984 8.42766 14C8.73032 14.002 9.02082 13.8591 9.23271 13.6036L13.6154 8.3186C13.8505 8.07706 13.9895 7.73066 13.9995 7.36282C14.0092 6.99497 13.8886 6.63886 13.6668 6.38047C13.445 6.12209 13.142 5.98476 12.8309 6.00135C12.5199 6.01816 12.2288 6.18759 12.0278 6.46873L12.0278 6.46875Z" :fill="fill"/>
    <path d="M22.216 19.4014L19.8113 17V17.0002C18.8256 18.5244 17.5263 19.8219 16 20.8062L18.4047 23.2076H18.4045C18.9031 23.7193 19.5893 24.0056 20.3042 23.9999C21.018 24.0007 21.703 23.7183 22.2084 23.215C22.714 22.7117 22.9986 22.0287 23 21.3157C23.0014 20.6029 22.7194 19.9188 22.216 19.4134L22.216 19.4014Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "OptionsIcon",
  props: {
    width: {
      type: [Number, String],
      default: 23
    },
    height: {
      type: [Number, String],
      default: 24      
    },
    fill: {
      type: String,
      default: 'none'
    },
    stroke: {
      type: String,
      default: '#70D0CE'
    },
    opacity: {
      type: [Number, String],
      default: 1      
    },
    viewBox: {
      type: [Number, String],
      default: '0 0 23 24'    
    },
  }
}
</script>

