import notification from "@/notification";
import restAdapter from "@/restAdapter";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import auth from "../auth/index";
import * as conf from "../conf.yml";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

if (conf.default.underMaintenance) {
  routes = [
    {
      name: "Trace",
      path: "/trace/:styleNumber",
      component: () => import("../views/inquiry/Trace.vue"),
    },
    {
      name: "Under Maintenance",
      path: "*",
      component: () => import("../views/UnderMaintenance.vue"),
    },
  ];
} else {
  routes = [
    {
      name: "Home",
      path: "/",
      component: () => import("../views/home/Home.vue"),
      meta: { hideForAuth: true },
    },
    {
      name: "Register",
      path: "/register",
      component: () => import("../views/user/Register.vue"),
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("../views/user/Login.vue"),
      meta: { hideForAuth: true },
    },
    {
      name: "Reset Password",
      path: "/reset_password",
      component: () => import("../views/user/ResetPassword.vue"),
    },
    {
      name: "SupplierRoleSelection",
      path: "/supplier_role",
      component: () => import("../views/user/SupplierRoleSelection.vue"),
      meta: { requiresAuth: true },
    },
    {
      name: "CompleteProfile",
      path: "/complete_profile",
      component: () => import("../views/user/CompleteProfile.vue"),
      meta: { requiresAuth: true },
    },
    {
      name: "Trace",
      path: "/trace/:styleNumber",
      component: () => import("../views/inquiry/Trace.vue"),
    },
    {
      path: "/preview",
      component: () => import("../views/dashboard/TraceView.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          name: "Trace Preview",
          path: "trace/:id",
          component: () => import("../views/inquiry/Trace.vue"),
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "/app",
      component: () => import("../views/dashboard/Index.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          name: "Dashboard",
          path: "",
          component: () => import("../views/dashboard/Dashboard.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Analytics",
          path: "analytics",
          component: () => import("../views/dashboard/pages/Analytics.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "User Profile",
          path: "user_profile",
          component: () => import("../views/dashboard/pages/UserProfile.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Business Profile",
          path: "business_profile/:id",
          component: () => import("../views/user/BusinessProfile.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Inquiry",
          path: "inquiry/:id",
          component: () => import("../views/inquiry/inquiry.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Assign Suppliers",
          path: "inquiry/:id/assign_suppliers",
          component: () => import("../views/inquiry/SupplierAssignment.vue"),
          meta: { requiresAuth: true },
        },
        // {
        //   name: "Inquiry List",
        //   path: "inquiries/",
        //   component: () => import("../views/inquiry/inquiryList.vue"),
        //   meta: { requiresAuth: true, requiresApproval: true },
        // },
        // {
        //   alias: "inquiries/",
        //   path: "inquiries/all/:inquiry_type?/:product_type?/:query?",
        //   component: () => import("../views/inquiry/inquiryList.vue"),
        //   meta: { requiresAuth: true, requiresApproval: true },
        // },
        // {
        //   name: "My Inquiries",
        //   path: "inquiries/own/:inquiry_type?/:product_type?/:query?",
        //   component: () => import("../views/inquiry/inquiryListOwn.vue"),
        //   meta: { requiresAuth: true, requiresApproval: true },
        // },
        // {
        //   name: "New Inquiries",
        //   path: "inquiries/new/:inquiry_type?/:product_type?/:query?",
        //   component: () => import("../views/inquiry/inquiryListNew.vue"),
        //   meta: { requiresAuth: true, requiresApproval: true },
        // },
        {
          name: "Collections",
          path: "collections/:collection_type?/:query?",
          component: () =>
            import("../views/collection/CollectionListActive.vue"),
          meta: { requiresAuth: true, requiresApproval: true },
        },
        {
          name: "New Collections",
          path: "collection/new",
          component: () => import("../views/collection/CreateCollection.vue"),
          meta: { requiresAuth: true, requiresApproval: true },
        },
        {
          name: "Completed Or Inactive Styles",
          path:
            "inquiries/completed_or_inactive/:status?/:product_type?/:query?",
          component: () => import("../views/inquiry/inquiryListInactive.vue"),
          meta: { requiresAuth: true, requiresApproval: true },
        },
        {
          name: "Approve Suppliers",
          path: "approve_suppliers",
          component: () => import("../views/user/ApproveSuppliers.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Activity Log",
          path: "activity_log",
          component: () => import("../views/user/ActivityLog.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Book a Meeting",
          path: "book_meeting",
          component: () => import("../views/inquiry/BookMeeting.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Feedback Form",
          path: "feedback_form",
          component: () => import("../views/user/FeedbackForm.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "ViewFeedback_form",
          path: "ViewFeedback_form",
          component: () => import("../views/user/ViewFeedback.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Tech Pack Development",
          path: "inquiry/:id/tech_pack_development",
          component: () => import("../views/inquiry/TechPackDevelopment.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "User Information",
          path: "users",
          component: () =>
            import("../views/user/CustomersAndSuppliersList.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Collection Management Dashboard",
          path: "management_dashboard",
          component: () =>
            import("../views/dashboard/InquiryManagementDashboard.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Fabric Sourcing Form",
          path: "inquiry/:id/fabric_sourcing_form",
          component: () => import("../views/inquiry/FabricSourcingForm.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Bom",
          path: "inquiry/:id/bom",
          component: () => import("../views/inquiry/Bom.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Style Costing Sheet",
          path: "inquiry/:id/style_costing_sheet",
          component: () => import("../components/Inquiry/StyleCostSheet.vue"),
          meta: { requiresAuth: true },
        },
        {
          name: "Collection",
          path: "collection/:id",
          component: () => import("../components/collection/Collection.vue"),
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      name: "Not Found",
      path: "*",
      component: () => import("../views/NotFound.vue"),
    },
  ];
}

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.checkAuth()) {
      next({
        name: "Login",
        query: { redirect: window.location.hash.substring(1) },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (auth.checkAuth()) {
      next({
        name: "Dashboard",
        // path: '/app',
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }

  if (to.matched.some((record) => record.meta.requiresApproval)) {
    const response = await restAdapter.get("/api/is_approved_supplier");
    const approved = response.data.approved;
    if (!approved) {
      next({
        path: "/app",
        query: { redirect: to.fullPath },
      });
      notification.error(
        "Sorry, Styles can only be viewed after your account is approved."
      );
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
