<template v-slot={ fill }>
  <div
    :style="`
      display: flex;
      width: ${width};
      height: ${height};
      border: ${border} solid ${borderColor};
      border-radius: 50%;
      background-color: ${fill};
      align-items: center;
      justify-content: center;
      box-shadow: 0px 7.66234px 13.7922px ${boxShadow};
    `"
  >
    <slot :fill="iconFill" />
  </div>
</template>

<script>
export default {
  name: "EllipseIcon",
  props: {
    width: {
      type: String,
      default: '69px'
    },
    height: {
      type: String,
      default: '69px'  
    },
    fill: {
      type: String,
      default: 'white'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    border:{
      type: String,
      default: 'none'
    }
  },
  data: () => ({
    boxShadow: 'rgba(64, 35, 105, 0.17)',
    iconFill: '#473068',
    borderColor: 'rgba(64, 35, 105, 0.17)',
  }),
  mounted() {
    switch(this.variant){
      case 'primary':
        this.boxShadow = 'rgba(64, 35, 105, 0.17)';
        this.iconFill = '#473068';
        this.borderColor = 'rgba(71, 48, 104, 0.31)';
        break;
      
      case 'danger':
        this.boxShadow = 'rgba(234, 122, 102, 0.21)';
        this.iconFill = '#EA7A66';
        this.borderColor = 'rgba(234, 122, 102, 0.31)';
        break;

      case 'success':
        this.boxShadow = 'rgba(112, 208, 206, 0.26)';
        this.iconFill = '#70D0CE;';
        this.borderColor = 'rgba(112, 208, 206, 0.5)';
        break;

      default:
        this.boxShadow = 'rgba(64, 35, 105, 0.17)';
        this.iconFill = '#473068';
        this.borderColor = 'rgba(64, 35, 105, 0.17)';
        break;
    }
  }
}
</script>
