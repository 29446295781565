<template>
    <tbody class="customizable-table">
      <tr v-if="title"><td :colspan="headers.length" class="title">{{ title }}</td><td class="actions-td"></td></tr>
      <tr v-if="displayHeaders">
        <td v-for="(header, index) in headers" :key="index" class="subtitle">{{ header.name }}</td>
        <td class="actions-td"></td>
      </tr>
      <tr v-if="subTitle"><td :colspan="headers.length" class="subtitle">{{ subTitle }}</td></tr>
      <tr v-for="(record, index) in data" :key="index">
        <td v-for="(cell, cellNo) in record" :key="cellNo" :class="headers[cellNo].type === 'disabled' ? 'disabled-td' : 'input-td'">
          <div v-if="headers[cellNo].type === 'suppliers'"  style="max-width: 100%;">
            <v-autocomplete
                clearable
                v-model="selectedSuppliers[index]"
                :items="suppliers"
                item-text="name_full"
                item-value="supplier_id"
                label=""
                multiple
                @blur="editData(index, cellNo, selectedSuppliers[index])"
            ></v-autocomplete>
          </div>
          <div v-else-if="headers[cellNo].type === 'measurement'">
            <v-autocomplete
                clearable
                v-model="selectedUnit[index]"
                :items="measurements"
                item-text="name"
                item-value="id"
                label=""
                @blur="editData(index, cellNo, selectedUnit[index])"
            ></v-autocomplete>
          </div>
          <div v-else-if="headers[cellNo].type === 'disabled'">
            {{ cell ? roundOf(cell) : cell }}
          </div>
          <div v-else style="display: flex;">
            <input
              :type="headers[cellNo].type || 'text'"
              :step="headers[cellNo].step || '.01'"
              :value="cell"
              :onkeypress="headers[cellNo].int ? 'return event.charCode >= 48 && event.charCode <= 57' : headers[cellNo].type === 'number' ? 'return event.charCode != 69 && event.charCode != 101 && event.charCode != 45' : 'return true'"
              @input="editData(index, cellNo, $event.target.value)"
            />
            <span v-if="headers[cellNo].percentage">%</span>
          </div>
        </td>
        <td class="actions-td">
          <div class="tooltip mb-1 mt-1">
            <v-btn
              @click="removeRow(index)"
              fab
              dark
              x-small
              color="error"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <span class="tooltiptext">Remove Record</span>
          </div>
        </td>
      </tr>
      <div class="tooltip mb-4">
        <v-btn
          @click="addRow"
          fab
          dark
          x-small
          color="purple darken-4"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
        <span class="tooltiptext">Add New Record</span>
      </div>
      <slot />
    </tbody>
</template>
<script>

import { round } from 'lodash';

export default {
  name: "CustomizableTableBody",
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    displayHeaders: {
      type: Boolean,
      default: true
    },
    displayRecords: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => [[]]
    },
    formula: {
      type: String,
      default: ''
    },
    setTo: {
      type: String,
      default: ''
    },
    suppliers: {
      type: Array,
      default: () => []
    },
    measurements: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedSuppliers: [],
    selectedUnit: [],
  }),
  watch: {
    data: {
      handler: function(){
        this.setSuppliersAndUnits()
      }
    }
  },
  methods: {
    roundOf(value) {
      return round(parseFloat(value), 2);
    },
    editData(index, cellNo, value){
      const newData = this.data;
      newData[index][cellNo] = this.headers[cellNo].type === 'number' ? this.roundOf(value) : value;
      if(this.formula && this.setTo){
        const formula = this.formula.replaceAll('column', `newData[${index}]`);
        const setTo = this.setTo.replace('column', `newData[${index}]`);
        const value = eval(formula);
        if(value && !isNaN(value)){
          eval(`${setTo} = ${value}`);
        } else {
          eval(`${setTo} = ${null}`);
        }
      }
      this.$emit('input-data', newData);
    },
    addRow(){
      const emptyRow = []
      for(let index = 0; index < this.headers.length; index++){
        emptyRow.push("");
      }
      const newData = this.data;
      newData.push(emptyRow);
      this.$emit('input-data', newData);
    },
    removeRow(index){
      const newData = this.data
      newData.splice(index, 1);
      this.$emit('input-data', newData);
    },
    setSuppliersAndUnits() {
        for(let index = 0; index < this.data.length; index++){
          if(this.headers.find(header => header.type === 'suppliers')){
            const indexOfSuppliers = this.headers.map(header => header.type).indexOf('suppliers');
            this.selectedSuppliers[index] = this.data[index][indexOfSuppliers];
          }
        }

        for(let index = 0; index < this.data.length; index++){
          if(this.headers.find(header => header.type === 'measurement')){
            const indexOfMeasurement = this.headers.map(header => header.type).indexOf('measurement');
            this.selectedUnit[index] = this.data[index][indexOfMeasurement];
          }
        }
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    text-align: center !important;
    background-color: $primaryLight;
    font-weight: bolder;
    font-size: 14pt !important;
    color: $primary;
  }

  .subtitle {
    background-color: $assignButton;
  }

  .disabled-td {
    background-color: $light;
  }

  .actions-td {
    border: none !important;
    width: 40px !important;
  }

  .v-btn {
    min-width: 10px;
    min-height: 10px;
    width: 20px;
    height: 20px;
  }

  input {
    min-width: 0px;
    width: 100%;
    height: 100%;
    border: none;
  }

  input:focus {
    outline: none;
  }

  /* Tooltip container */
  .tooltip {
    float: left;
    position: relative;
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    transition-delay: 1s;
    visibility: visible;
  }
</style>
