<template>
    <span>
      <ellipse-icon 
        :width="'59px'" 
        :height="'59px'" 
        border="2px"
        :variant="supplierStatus && supplierStatus == 1 ? 'primary' : 'danger'" 
        v-slot="props"
      >  
        <design-icon v-if="traceName=='DesignIcon'" :fill="props.fill"/>
        <raw-material-icon v-else-if="traceName=='RawMaterialIcon'" :fill="props.fill"/>
        <spinning-icon v-else-if="traceName=='SpinningIcon'" :fill="props.fill"/>
        <weaving-icon v-else-if="traceName=='WeavingIcon'" :fill="props.fill"/>
        <dyeing-icon v-else-if="traceName=='DyeingIcon'" :fill="props.fill"/>
        <sampling-icon v-else-if="traceName=='SamplingIcon'" :fill="props.fill"/>
        <trims-icon v-else-if="traceName=='TrimsIcon'" :fill="props.fill"/>
        <manufacture-icon v-else-if="traceName=='ManufacturingIcon'" :fill="props.fill"/>
        <delivery-icon v-else-if="traceName=='DeliveryIcon'" :fill="props.fill"/> 
      </ellipse-icon>
    </span>
</template>

<script>
export default {
  name: "TraceServicesIcon",
  props: {
    traceName: {
      type: String,
      default: null,
    },
    supplierStatus:{
      type: Number,
      default: null,
    }
  },
}

</script>

<style scoped>
      
</style>
