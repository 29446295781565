<template>
  <md-button :class="className" @click="clicked" :disabled="disabled"><slot/>{{ title }}</md-button>
</template>

<script>
export default {
  name: "UiButton",
  props: {
    className: {
      type: String,
      default: "primary"
    },
    title: {
      type: [String, Number],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-button {
    align-items: center;
    text-transform: none !important;
  }
  .primary {
    background-color: $tableBorder !important;
    color: #FFFFFF !important;
  }
  .primary-outlined {
    border: 1px solid $tableBorder !important;
    background-color: #FFFFFF !important;
    color: $tableBorder !important;
    font-weight: normal !important;
  }
  .danger {
    background-color: $danger !important;
    color: #FFFFFF !important;
  }
  .disable{
    border: 1px solid $tableBorder !important;
    background-color: #FFFFFF !important;
    color: $tableBorder !important;
  }
  .success-full {
    background-color: $success !important;
    color: #FFFFFF !important;
  }
  .v-application .lighter {
    background-color: $popupWindow !important;
    border: 1px solid $tableBorder !important;
    color: $tableBorder !important;
  }
  .long {
    border-radius: 3px !important;
    min-width: 117px !important;
    height: 32px !important;
  }
  .short {
    border-radius: 3px !important;
    min-width: 82px !important;
    height: 32px !important;
  }
  .space {
    word-spacing: 5px;
  }
</style>
