import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    toggleScroll: true,
    drawer: true,
    barColor: "#492a67 0%, #582963  100%",
    barImage: require(`../assets/sidebar-1.jpg`),
    inquiryViewMode: true,
    routeLeaveAlert: false,
    onRouteLeaveAlertPage: false,
    inquiryStatus: "active",
    accountManagers: [],
    loadingCard: null,
    reloadDropCard: null,
    updateInquiryInAppBar: false,
  },
  mutations: {
    SET_SCROLL_TOGGLE(state, payload) {
      state.toggleScroll = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_INQUIRY_VIEW_MODE(state, payload) {
      state.inquiryViewMode = payload;
    },
    SET_ROUTE_LEAVE_ALERT(state, payload) {
      state.routeLeaveAlert = payload;
    },
    SET_INQUIRY_STATUS(state, payload) {
      state.inquiryStatus = payload;
    },
    SET_ACCOUNT_MANAGER(state, payload) {
      state.accountManagers = payload;
    },
    SET_LOADING_CARD(state, payload) {
      state.loadingCard = payload;
    },
    SET_RELOAD_DROP_CARD(state, payload) {
      state.reloadDropCard = payload;
    },
    SET_UPDATE_INQUIRY_IN_APP_BAR(state, payload) {
      state.updateInquiryInAppBar = payload;
    }
  },
  actions: {},
  modules: {}
});
